import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, FormControl, Button } from '@material-ui/core';
import ImageCollageCard from '../components/ImageCollageCard';
import {getVerticalImages} from '../services/entity';



export default function BaselinePhotos({water_body_id}) {
    const [verticalCategory, setVerticalCategory] = React.useState(null);
    const [verticalImages, setVerticalImages] = React.useState([]);

    useEffect(() => {
        if(water_body_id){
            setVerticalCategoryValue('type of water-body')
        }
    }, [water_body_id]);

    const categoryToLabel = {
        'type of water-body': 'Type Of Water Body',
        'condition_state': 'Condition/ State',
        'infrastructure': 'Infrastructure',
        'aesthetics': 'Aesthetics',
        'quality': 'Quality',
        'solid waste': 'Solid Waste',
        'wastewater': 'Wastewater',
        'od-ou': 'OD-OU',
    }

    const setVerticalCategoryValue = (value) => {
        getVerticalImages({'water_body_id': water_body_id, 'vertical_category': value}).then(resp => 
            {  
                let images = []
                for (let key in resp) {
                    images = images.concat(resp[key])
                    setVerticalImages(images);
                }
                setVerticalCategory(value);
            }
        )
    }

    return (
        <>
            <h3>Baseline Photos</h3>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="vertical-category" name="row-radio-buttons-group" defaultValue="type of water-body" value={verticalCategory}>
                    {['type of water-body', 'condition_state', 'infrastructure', 'aesthetics', 'quality', 'solid waste', 'wastewater', 'od-ou'].map(c => <FormControlLabel value={c} control={<Radio onClick={(e) => setVerticalCategoryValue(e.target.value)}/>} label={categoryToLabel[c]} />)}
                </RadioGroup>
            </FormControl>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ImageCollageCard key={verticalImages+water_body_id} imageList={verticalImages}/>
            </div>
        </>
    );
}
