import * as React from 'react';
import { ReactPhotoCollage } from "react-photo-collage";
// import image1 from './../images/water.jpeg'

export default function ImageCollageCard({imageList, height=200, width=200, layout=[2]}) {
    const setting = {
        width: '500px',
        height: ['250px', '170px'],
        layout: layout,
        photos: imageList.map(i => {return { "source": i }}),
        showNumOfRemainingPhotos: true
    };

    return (
        <div>
            <ReactPhotoCollage {...setting} />
        </div>
    );
}