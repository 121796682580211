import React, { useEffect, useState } from 'react';
import { FormControlLabel, Button, FormGroup, Checkbox } from '@material-ui/core';
import MapCard from '../components/MapCard';


export default function MapComponent({waterBody, waterBodyMapDetails, height="600px"}) {
  // var jwt = require("jsonwebtoken");

  const [contourToggle, setContourToggle] = useState(true);
  const [orthoToggle, setOrthoToggle] = useState(false);
  const [shapesToggle, setShapesToggle] = useState(true);

//   useEffect(() => {
//     let params = getParamsBasedOnEntityType(entityType)
//     params['number'] = dashboard
//     params['type'] = "dashboard"
//     getMetabaseIframeUrl(params).then(resp => setIframeUrl(resp))
//   }, []);

  const shapeColorList = [
    {'name': 'TREES', 'color': 'green'},
    {'name': 'ROAD', 'color': 'gray'},
    {'name': 'BUILDING', 'color': 'red'}, 
    {'name': 'FARMLAND', 'color': 'lightgreen'},
    {'name': 'WATER BODY', 'color': 'blue'},
  ]
  

  return (
    <div className="something" style={{height: '100%'}}>
        <div style={{display: "flex", justifyContent: "left"}}>
            
            {shapeColorList.map(s => <div>
            <div style={{ width: "12px",
                display: "inline-block",
                height: "10px",
                background: s.color,
                margin: "3px 8px",
                borderRadius: "2px"
            }}>
            </div> {s.name}
            </div>
            )}

            <div>
            <FormGroup row>
                <FormControlLabel
                control={<Checkbox checked={contourToggle} onChange={(e) => setContourToggle(!contourToggle)} />}
                label="Contour" />
                <FormControlLabel
                control={<Checkbox checked={orthoToggle} onChange={(e) => setOrthoToggle(!orthoToggle)}/>}
                label="Ortho" />
                <FormControlLabel
                control={<Checkbox checked={shapesToggle} onChange={(e) => setShapesToggle(!shapesToggle)}/>}
                label="Shapes" />
            </FormGroup>
            </div>

        </div>

        <MapCard
        isMisMarkerShown={true}
        waterBodies={[waterBody]}
        waterBody={waterBody}
        waterBodyMapDetails={waterBodyMapDetails}
        // entity={village || block || district || state}
        contourToggle={contourToggle}
        orthoToggle={orthoToggle}
        shapesToggle={shapesToggle}
        // entityCoordinates={entityCoordinates}
        height={height}
        />
    </div> 
  );
}