import * as React from 'react';
import { FormControlLabel, Radio, RadioGroup, FormControl, Button } from '@material-ui/core';
import Link from '../components/Link';


export default function DownloadsComponent({waterBodyMapDetails}) {
    const [downloadCategory, setDownloadCategory] = React.useState('report');   

    const toTitleCase = (str) => {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    }

    return (
        <>
        <h3>Downloads</h3>
            <FormControl component="fieldset">
            <RadioGroup row aria-label="download-category" name="row-radio-buttons-group" defaultValue="report" value={downloadCategory}>
                {['report', 'reference'].map(c => <FormControlLabel value={c} control={<Radio onClick={(e) => setDownloadCategory(e.target.value)}/>} label={toTitleCase(c)} />)}
            </RadioGroup>
            </FormControl>
            {
            downloadCategory === 'report'?
            <div style={{display: 'flex', justifyContent: 'space-around', margin: '10px'}}>
                <Button disabled={!waterBodyMapDetails?.recommendations_url}>
                    <Link to={waterBodyMapDetails?.recommendations_url} target="_blank" style={{textDecoration: 'none'}} download>
                        Recommendation Report
                    </Link>
                </Button>
                <Button disabled={!waterBodyMapDetails?.report_url}>
                    <Link to={waterBodyMapDetails?.report_url} target="_blank" style={{textDecoration: 'none'}} download>
                        Topographic Survey Report
                    </Link>
                </Button>
            </div> :
            <div style={{display: 'flex', justifyContent: 'space-around', margin: '10px'}}>
                <Button disabled={!waterBodyMapDetails?.scoring_toolkit_url}>
                    <Link to={waterBodyMapDetails?.scoring_toolkit_url} target="_blank" style={{textDecoration: 'none'}} download>
                        Scoring Toolkit
                    </Link>
                </Button>
                <Button disabled={!waterBodyMapDetails?.intervention_sop_url}>
                    <Link to={waterBodyMapDetails?.intervention_sop_url} target="_blank" style={{textDecoration: 'none'}} download>
                        Intervention SoP
                    </Link>
                </Button>
                <Button disabled={!waterBodyMapDetails?.glossary_url}>
                    <Link to={waterBodyMapDetails?.glossary_url} target="_blank" style={{textDecoration: 'none'}} download>
                        Glossary
                    </Link>
                </Button>
            </div>
            }
        </>
    );
}
